import { createNamespacedHelpers } from 'vuex';
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper';
import { Portals, Getters, Mutations, Actions } from '@/models/Portal.model';

// Inital state
const state: Portals = {
  portalTypes: [],
};

// Gets the values of our state usually called from components
const getters: DefineGetters<Getters, Portals> = {
  portalTypes: (state) => state.portalTypes,
};

// Sets values or changes to state. Must be called via an action.
const mutations: DefineMutations<Mutations, Portals> = {
  setPortalTypes(state, { portalTypes }) {
    state.portalTypes = portalTypes;
  },

};

// Async functions that will contain the logic / service calls and commit mutations to state
const actions: DefineActions<Actions, Portals, Mutations, Getters> = {
  setPortalTypes({ commit }, payload) {
    commit('setPortalTypes', payload);
  },

};

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers<Portals, Getters, Mutations, Actions>('PortalModule');

export const PortalModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
