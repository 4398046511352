import { createNamespacedHelpers } from 'vuex';
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper';
import { AppData, Getters, Mutations, Actions } from '@/models/App.model';

// Inital state
const state: AppData = {

  snackbar: {
    show: false,
    text: '',
    color: 'error',
    mode: '',
    timeout: Number(process.env.VUE_APP_SNACKBAR_TIMEOUT),
    x: '',
    y: 'top',
    icon: '',
    closeIcon: 'mdi-close-circle-outline',
  },

  drawer: null,
  mini: false,
  
};

// Gets the values of our state usually called from components
const getters: DefineGetters<Getters, AppData> = {

  urls: (state) => {
    return {
      login: process.env.VUE_APP_URL_LOGIN,
    };
  },

  icons: (state) => {
    return {
      // --- generic ---
        // -- informational
        date: 'mdi-calendar-outline',
        email: 'mdi-email',
        error: 'mdi-alert',
        favourite: 'mdi-star',
        info: 'mdi-information',
        inProgress: 'mdi-progress-clock',
        no: 'mdi-close-thick',
        password: 'mdi-lock',
        pdf: 'mdi-file-pdf-box',
        profile: 'mdi-account',
        settings: 'mdi-cog',
        success: 'mdi-check-circle',
        telegram: 'mdi-telegram',
        time: 'mdi-clock',
        username: 'mdi-account',
        warning: 'mdi-bell',
        web: 'mdi-web',
        whatsapp: 'mdi-whatsapp',
        yes: 'mdi-check-bold',
        // --- actions ---
        activate: 'mdi-play',
        close: 'mdi-close-circle-outline',
        contract: 'mdi-menu-up', 
        copy: 'mdi-content-copy', 
        delete: 'mdi-delete',
        download: 'mdi-download',
        edit: 'mdi-pencil',
        expand: 'mdi-menu-down', 
        filter: 'mdi-filter-variant',
        login: 'mdi-fingerprint',
        next: 'mdi-chevron-right',
        previous: 'mdi-chevron-left',
        print: 'mdi-printer',
        refresh: 'mdi-refresh-circle',
        register: 'mdi-account-plus',
        search: 'mdi-magnify',
        suspend: 'mdi-pause',
        view: 'mdi-eye',
        
      // --- app specific ---
        // --- informational ---
        // --- actions ---
        // --- nav ---
        // --- profile nav ---
        // --- additional ---        
    };
  },

  messages: (state) => {
    return {
      couldNotConnect: 'Error: Please contact Eazigear Support for assistance.',
      sessionTimeout: 'Your session has timed out, please login to continue.',
    };
  },

  snackbar: (state) => state.snackbar,
  drawer: (state) => state.drawer,
  mini: (state) => state.mini,

  infoDialogs: (state) => {
    return {      
      cellphone: {
        title: 'Why do we need your Cellphone Number?',
        content: 'We need your cellphone number as a primary contact in order to communicate with you quickly and efficiently.',
      },      
      idNumber: {
        title: 'Why do we need a copy of your ID?',
        content: 'We need a copy of your ID.',
      },      
    };
  },

  // https://codeburst.io/vuex-getters-are-great-but-dont-overuse-them-9c946689b414
  // from what I gather, in order to make a getter update itself, it needs to be parameterised
  // this was not working previously using getters on the actual vue files, even when they were
  // calling a method in AppService. They would not refresh on each call.
  // because we need this to be a generic global method, I have written it here as a getter.
  autoId: (state) => (random: string): string => {
    let autoId: string = ''; 
    const dict: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; 
    
    for (let i = 0; i < 12; i++) { 
      autoId += dict.charAt(Math.floor(Math.random() * dict.length)); 
    } 

    return autoId;
  },

  maxWidthButton: (state) => (currentBreakpoint: string, maxWidthBreakpoints: string[]): string => {
    let returnValue = '';
    for (const breakpoint of maxWidthBreakpoints) {
      if (currentBreakpoint === breakpoint) {
        returnValue = '100%';
        break;
      }
    }

    return returnValue;
  },
};

// TODO: Change mutation names to uppercase ( Standards )
// Sets values or changes to state. Must be called via an action.
const mutations: DefineMutations<Mutations, AppData> = {

  snackbar(state, { show, text, color, icon }) {
    state.snackbar.show = show;
    state.snackbar.text = text;
    state.snackbar.color = color;
    state.snackbar.icon = icon;
  },

  snackbarWithCustomTimeout(state, { show, text, color, icon, timeout }) {
    state.snackbar.show = show;
    state.snackbar.text = text;
    state.snackbar.color = color;
    state.snackbar.icon = icon;
    state.snackbar.timeout = timeout;
  },

  setDrawer(state, payload) {
    state.drawer = payload;
  },

  setMini(state, payload) {
    state.mini = (payload as unknown) as boolean;
  },

};

// Async functions that will contain the logic / service calls and commit mutations to state
const actions: DefineActions<Actions, AppData, Mutations, Getters> = {

  snackbar({ commit }, payload) {
    commit('snackbar', payload);
  },

  snackbarWithCustomTimeout({ commit }, payload) {
    commit('snackbarWithCustomTimeout', payload);
  },

  setDrawer({ commit }, payload) {
    commit('setDrawer', payload);
  },

  setMini({ commit }, payload) {
    commit('setMini', payload);
  },

};

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers<AppData, Getters, Mutations, Actions>(
  'AppModule',
);

export const AppModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
