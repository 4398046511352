// Vue
import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import { AppModule } from './modules/app';
import { AuthModule } from './modules/auth';
import { PortalModule } from './modules/portal';

Vue.use(Vuex);

const store = new Vuex.Store<any>({
  modules: {
    app: AppModule,
    auth: AuthModule,
    portals: PortalModule,
  },
});

export default store;
