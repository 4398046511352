

























// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InfoDialog extends Vue {

  @Prop({default: ''})
  title!: string;

  @Prop({default: ''})
  content!: string;

}
