// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const theme = {
  primary: '#6ac5f4',
  secondary: '#1c2b39',
  accent: '#fc7229',  
  success: '#49b675',
  info: '#0a2b2b',
  warning: '#5f8a8b',
  error: '#da291c',
};

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' },
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
