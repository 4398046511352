// Imports
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// create object router with the valid initialization
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    }
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '',
      beforeEnter: (to, from, next) => {
        next();
      },
      component: () => import('@/layouts/page/Index.vue'),
      children: [
        {
          name: 'Login',
          path: '',
          component: () => import('@/views/Login.vue'),
        },
        {
          name: 'Register',
          path: 'signup',
          component: () => import('@/views/Register.vue'),
        },
        {
          name: 'Verify',
          path: 'verify/:token?',
          component: () => import('@/views/Verify.vue'),
        },
        {
          name: 'Accept Invitation',
          path: 'accept/:token?',
          component: () => import('@/views/AcceptInvitation.vue'),
        },
        {
          name: 'Forgotten Password',
          path: 'forgot',
          component: () => import('@/views/Password_Forgot.vue'),
        },
        {
          name: 'Set Password',
          path: 'set/:token?',
          component: () => import('@/views/Password_Set.vue'),
        },
      ],
    },
  ],
});

// export router as default
export default router;
